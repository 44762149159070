import ModalsTabs from "components/Tabs/ModalsTabs"
import { useState } from "react";
import AddSingleEntry from "../Tabs/AddSingleEntry";
import AddMultipleEntry from "../Tabs/AddMultipleEntry";
import { useTranslation } from "react-i18next";

const AddEntryModal = ({ setOpenAddEntryModal }) => {
    const { t } = useTranslation(["global", "services"]);

    const [selectedPageTab, setSelectedPageTab] = useState("tab1");

    const tabs = [
        { id: "tab1", name: t("services:singleEntry") },
        { id: "tab2", name: t("services:multipleEntry") },
    ];

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                ></span>
                <div
                    className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-modalBg">
                        <div className="flex justify-end px-2 py-1">
                            <i
                                onClick={() => setOpenAddEntryModal(false)}
                                className="text-2xl cursor-pointer ri-close-line text-passive"
                            ></i>
                        </div>

                        <div className="flex flex-col items-center px-5 pt-4 pb-10 bg-modalBg">
                            <ModalsTabs
                                tabs={tabs}
                                selectedPageTab={selectedPageTab}
                                setSelectedPageTab={setSelectedPageTab}
                            />

                            <div className="w-full mt-5">
                                {selectedPageTab === "tab1" && <AddSingleEntry setOpenAddEntryModal={setOpenAddEntryModal} />}
                                {selectedPageTab === "tab2" && <AddMultipleEntry setOpenAddEntryModal={setOpenAddEntryModal} />}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEntryModal