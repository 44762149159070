import MakdosTable from "components/MakdosTable";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "store/GlobalStore";
import moment from "moment";
import { useNavigate } from "react-router";

const RequestTicketTable = ({ requestList, onGetRequestTicketList, numberOfPages }) => {
    const { t } = useTranslation(["support", "global", "services"]);
    const [data, setData] = useState(requestList);
    const navigate = useNavigate();

    const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);
    const pageLimits = [10, 25, 50, 100];
    const [activePageSize, setActivePageSize] = useState(pageLimit);

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: t("support:subject"),
            accessor: "title",
            // Cell: ({ value }) => (value.length > 30 ? value.substring(0, 30) + "..." : value)
        },
        {
            Header: t("support:sender"),
            accessor: "email",
            // Cell: ({ value }) => (value.length > 30 ? value.substring(0, 30) + "..." : value)
        },
        {
            Header: t("global:date"),
            accessor: "create_time",
            Cell: (props) => props.value !== null ? moment(props.value).format("DD-MM-YYYY HH:mm") : "-"
        },
        {
            Header: t("services:ipAddress"),
            accessor: "ip_address"
        },
        {
            Header: "Ayarlar",
            accessor: "idbutton",
            Cell: tableButton
        },

    ], []);

    function tableButton(props) {
        if (props.row.original.id) {
            return <div>
                <i className="cursor-pointer ri-eye-line text-main" onClick={() => navigate(`/ticket-and-support/request-tickets/${props.row.original.id}`)} />
            </div>
        } else {
            <></>
        }
    }

    return (
        <div>
            {requestList && requestList !== "ticket_not_found" &&
                <div className="w-full mb-12 ">
                    <MakdosTable
                        isCheckboxVisible={false}
                        setCheckList={() => {
                        }}
                        checkList={[]}
                        data={data}
                        setData={setData}
                        showPagination={true}
                        columns={columns}
                        pageLimits={pageLimits}
                        isBackendPagination={true}
                        backendPaginationMethod={(page, limit) => {
                            onGetRequestTicketList(page, limit);
                        }}
                        backendNumberOfPages={numberOfPages}
                        activePageSize={activePageSize}
                        setActivePageSize={setActivePageSize}
                        isDndAvailable={false}
                    />
                </div>
            }

        </div>
    )
}

export default RequestTicketTable