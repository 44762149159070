import React, { useState } from "react";

export default function FileUploader(props) {
  const [newName, setNewName] = useState("Dosya Seçiniz");

  function addFile(file) {
    if (props.maxFile) {
      if (file?.length <= props.maxFile) {
        let newVal = [];
        for (const [key, value] of Object.entries(file)) {
          if (value.size <= 10705702) {
            newVal.push(value);
          } else {
            alert(`Maksimum 10 MB boyutunda dosya yükleyebilirsiniz.`);
          }
        }
        setNewName(newVal);
        props.setValue(file);
      } else {
        alert(`Maksimum ${props.maxFile} adet dosya yükleyebilirsiniz.`);
      }
    } else {
      let newVal = [];
      for (const [key, value] of Object.entries(file)) {
        newVal.push(value);
      }
      setNewName(newVal);
      props.setValue(file);
    }
  }



  const onDeleteFile = (name) => {
    let newFileList = [];
    newName.map((n) => {
      if (n.name !== name) {
        newFileList.push(n);
      }
    });
    setNewName(newFileList);
    props.setValue(newFileList);
  };
  return (
    <div className="">
      <label
        className={
          "block float-left w-full pr-4 mb-1 text-sm text-main md:text-left md:mb-0 " +
          props.labelStyle
        }
        htmlFor={props.id}
      >
        {props.label}
      </label>
      <div
        className="box-border flex relative flex-wrap justify-start pr-1 w-full align-middle cursor-pointer text-secondary">
        <div className="box-border relative flex-wrap w-full text-xl">
          <div
            className={
              props.inputStyle +
              " border-2 rounded-md " +
              (props.disabled
                ? "bg-primary-disabled border-primary-disabled text-gray-600"
                : props.message !== ""
                  ? "form-error"
                  : "input-text-style text-gray-700") +
              " float-left w-full px-3 py-1 text-sm leading-tight appearance-none  focus:outline-none focus:bg-white  "
            }
          >
            <input
              id={props.id}
              type="file"
              className="hidden"
              disabled={props.disabled}
              multiple={props.multiple}
              accept="image/x-png,image/gif,image/jpeg,image/jpg, application/pdf, text/plain,application/zip, application/x-rar-compressed"
              onChange={(e) => {
                addFile(e.target.files);
              }}
            />
            <a
              onClick={() => {
                if (!props.disabled) document.getElementById(props.id).click();
              }}
              className="float-left absolute py-1 pr-3 mt-2 rounded-sm cursor-pointer focus:shadow-outline focus:outline-none"
            >
              <i className="ri-attachment-line text-primary-hover" />
            </a>
            <div
              id={props.file}
              className="flex overflow-x-auto items-center pr-6 pl-6 h-10 max-h-20 bg-transparent border-none outline-none customScroll focus:outline-none"
            >
              {newName !== "Dosya Seçiniz" &&
                newName.map((n) => {
                  return (
                    <div className="flex items-center px-2 py-1 mr-3 rounded-md border border-borderGray2">
                      <i
                        onClick={() => onDeleteFile(n.name)}
                        className="mr-2 ri-close-line text-alert-red"
                      ></i>
                      <p className="mr-5">
                        {n.name?.length < 6 ? (
                          n.name
                        ) : (
                          <a title={n.name}>{n.name.substring(0, 6) + "..."}</a>
                        )}
                      </p>
                    </div>
                  );
                })}
            </div>
            {/* <input
              type="text"
              id={props.file}
              className="float-left pr-6 pl-6 mt-1 w-full h-6 bg-transparent border-none cursor-pointer outline-none focus:outline-none"
              disabled
              value={newName}
            /> */}
            {/* <a
              href={
                props.defaultText !== null
                  ? 'http://api.thanos.makdos.com' + props.defaultText.split('thanos__fe_smt')[1]
                  : ''
              }
              className={
                'absolute right-0 inline-block w-8 h-6 mt-1 pl-2 mr-1 cursor-pointer text-base ' +
                (props.disabled
                  ? 'text-gray-500 border-gray-500'
                  : 'text-primary-hover border-border')
              }
              download={
                props.defaultText !== null
                  ? 'http://api.thanos.makdos.com' + props.defaultText.split('thanos__fe_smt')[1]
                  : ''
              }
              target="_blank"
            >
              <i className="ri-file-download-line" />
            </a> */}
          </div>
        </div>
        <label className="w-full text-sm form-error">{props.message}</label>
      </div>
    </div>
  );
}
