import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useParams, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Button } from "components";
import { getIYS, } from "services/allServices";
import Tabs from "components/Tabs";
import { isNullOrEmptyOrUndefined } from "utils/common";
import IYSFastTransactions from "components/Services/IYSServices/Tabs/IYSFastTransactions";
import IYSMessagePermissions from "components/Services/IYSServices/Tabs/IYSMessagePermissions";
import IYSCallPermissions from "components/Services/IYSServices/Tabs/IYSCallPermissions";
import IYSEmailPermissions from "components/Services/IYSServices/Tabs/IYSEmailPermissions";
import AddEntryModal from "components/Services/IYSServices/Modal/AddEntryModal";

const IYSServiceDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(["global", "services"]);
  const navigate = useNavigate();
  let slug = useParams();

  let { id } = useParams();
  const [pageData, setPageData] = useState();
  const [openAddEntryModal, setOpenAddEntryModal] = useState(false);

  const downloadExampleCSV = () => {
    const exampleCsvUrl = "/iys_users.csv";

    const link = document.createElement("a");
    link.href = exampleCsvUrl;
    link.download = "iys_users.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onGetPageData = () => {
    setIsLoading(true);
    getIYS(id).then(res => {
      setIsLoading(false);
      setPageData(res);
      if (!res) {
        navigate("/");
      }
    });
  };

  const tabs = [
    {
      name: t("services:quickTransactions"),
      id: "1",
      component: <IYSFastTransactions data={pageData} onGetPageData={onGetPageData} />,
      slug: "",
      visible: true
    },
    {
      name: t("services:messagePermissions"),
      id: "2",
      component: <IYSMessagePermissions data={pageData} />,
      slug: "message-permissions",
      visible: pageData?.brand_code_status === false ? false : true
    },
    {
      name: t("services:callPermissions"),
      id: "3",
      component: <IYSCallPermissions data={pageData} />,
      slug: "call-permissions",
      visible: pageData?.brand_code_status === false ? false : true
    },
    {
      name: t("services:emailPermissions"),
      id: "4",
      component: <IYSEmailPermissions data={pageData} />,
      slug: "email-permissions",
      visible: pageData?.brand_code_status === false ? false : true
    },
  ];

  useEffect(() => {
    onGetPageData();
  }, [id]);

  return (
    <>
      {
        isLoading ?
          <div className="flex justify-center items-center mt-20 w-full">
            <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
              type="Oval"
              color="#ff7c30"
              height={75}
              width={75}
              timeout={3000}
            />
          </div>
          :
          (pageData && pageData?.status !== "waiting") ?
            <div className="container relative w-full">
              <div className="justify-between items-center p-4 bg-white rounded-md shadow-sm lg:flex">
                <div>
                  <h2 className="font-semibold">{pageData?.product_name}</h2>
                  <p className="text-sm text-passive">ID #{pageData?.id}</p>
                </div>
                <div className="items-center space-x-4 lg:flex">
                  <Button
                    onClick={() => downloadExampleCSV()}
                    className="px-8 mt-3 lg:mt-0"
                    green
                  >
                    <i className="ri-history-line" />
                    <span>{t("services:downloadExampleExcel")}</span>
                  </Button>
                  <Button
                    onClick={() => setOpenAddEntryModal(true)}
                    className="px-8 mt-3 lg:mt-0"
                    red
                  >
                    <i className="ri-history-line" />
                    <span>{t("services:uploadExcel")}</span>
                  </Button>
                  <Button
                    // onClick={() => setOpenExtendServiceModal(true)}
                    className="px-8 mt-3 lg:mt-0"
                    secondary
                    disabled
                  >
                    <i className="ri-history-line" />
                    <span>{t("services:export")}</span>
                  </Button>
                </div>
              </div>

              <div className="mt-4">
                <Tabs
                  tabs={tabs.filter((x) => x.visible)}
                  selectedTab={
                    !isNullOrEmptyOrUndefined(slug.url)
                      ? tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.id ?? tabs.filter((x) => x.visible)[0]?.id
                      : tabs.filter((x) => x.visible)[0]?.id
                  }
                  setSelectedTab={(v) => {
                    navigate(
                      "/services/iys/" + slug.id + "/" +
                      tabs.find((x) => x.id === v).slug
                    );
                  }}
                />

                <div>
                  {!isNullOrEmptyOrUndefined(slug.url)
                    ? (tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.component) ?? tabs.filter((x) => x.visible)[0]?.component
                    : tabs.filter((x) => x.visible)[0]?.component
                  }
                </div>
              </div>

              {openAddEntryModal &&
                <AddEntryModal setOpenAddEntryModal={setOpenAddEntryModal} />
              }
            </div> :
            <div className="flex flex-col justify-center items-center mt-20">
              <img src="/servicesWaiting.svg" width="352" height="330" />
              <p className="mt-3 text-5xl font-semibold text-primary-hover">{t("services:preparedService")}</p>
              <p className="my-2">{t("services:preparedServiceDesc1")}</p>
              <p>{t("services:preparedServiceDesc2")}</p>
            </div>
      }
    </>
  )
}

export default IYSServiceDetail