import Button from "components/FormElements/Button";
import ComboBox from "components/FormElements/ComboBox"
import TextField from "components/FormElements/Input/TextField";
import { useComboBox } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { createAddSingleEntryIYS } from "services/allServices";

const AddSingleEntry = ({ setOpenAddEntryModal }) => {
    const { t } = useTranslation(["global", "services"]);
    const slug = useParams();
    const { addToast } = useToasts();

    const [recipient, setRecipient] = useState("");
    const [selectedType, typeList, changeType, setTypeList] = useComboBox(t("services:selectEntryType"), [
        { id: 1, text: "EPOSTA" },
        { id: 2, text: "MESAJ" },
        { id: 3, text: "ARAMA" }
    ]);

    const [selectedSource, sourceList, changeSource, setSourceList] = useComboBox(t("services:selectSource"), [
        { id: 1, text: "HS_FIZIKSEL_ORTAM" },
        { id: 2, text: "HS_ISLAK_IMZA" },
        { id: 3, text: "HS_ETKINLIK" },
        { id: 4, text: "HS_ATM" },
        { id: 5, text: "HS_EORTAM" },
        { id: 6, text: "HS_WEB" },
        { id: 7, text: "HS_MOBIL" },
        { id: 8, text: "HS_MESAJ" },
        { id: 9, text: "HS_EPOSTA" },
        { id: 10, text: "HS_CAGRI_MERKEZI" },
        { id: 11, text: "HS_SOSYAL_MEDYA" }
    ]);

    const [selectedStatus, statusList, changeStatus, setStatusList] = useComboBox(t("services:status"), [
        { id: 1, text: "ONAY" },
        { id: 2, text: "RET" }
    ]);

    const handleAddSingleEntry = async () => {
        if (recipient === "" || selectedType.id === 0 || selectedSource.id === 0 || selectedStatus.id === 0) {
            addToast("Lütfen tüm alanları doldurunuz.", { appearance: "error", autoDismiss: true });
            return
        }

        let params = {
            owned_product_id: slug.id,
            type: selectedType.text,
            source: selectedSource.text,
            recipient: recipient,
            status: selectedStatus.text,
            consentDate: new Date().toISOString()
        }

        await createAddSingleEntryIYS(params).then(res => {
            if (res.status === "single_data_added") {
                addToast("Kayıt başarıyla eklendi.", { appearance: "success", autoDismiss: true });
                setOpenAddEntryModal(false);
            } else {
                addToast("Kayıt eklenirken bir hata oluştu.", { appearance: "error", autoDismiss: true });
            }
        })
    }

    return (
        <div>

            <div className="grid grid-cols-2 gap-6">
                <ComboBox
                    id="add_single_entry"
                    name="add_single_entry"
                    selected={selectedType}
                    setSelected={changeType}
                    list={typeList}
                    label={`${t("services:selectEntryType")} *`}
                    isSearchable={false}
                    className="z-10"
                />

                <ComboBox
                    id="add_source"
                    name="add_source"
                    selected={selectedSource}
                    setSelected={changeSource}
                    list={sourceList}
                    label={`${t("services:selectSource")} *`}
                    isSearchable={false}
                    className="z-60"
                />
            </div>

            <div className="grid grid-cols-2 gap-6 pt-6">
                <TextField
                    id="recipient"
                    name="recipient"
                    type="text"
                    value={recipient}
                    onChange={setRecipient}
                    ref={null}
                    label={`${t("services:recipient")} *`}
                    placeholder="ornek@makddos.com"
                />

                <ComboBox
                    id="status"
                    name="status"
                    selected={selectedStatus}
                    setSelected={changeStatus}
                    list={statusList}
                    label={`${t("services:status")} *`}
                    isSearchable={false}
                />
            </div>

            <div className="flex justify-end">
                <Button primary onClick={() => handleAddSingleEntry()} className="mt-4 w-32 !hover:bg-gray-400">
                    {t("global:add")}
                </Button>
            </div>
        </div>
    )
}

export default AddSingleEntry