import styled, { css } from "styled-components";

const Button = styled.button`
  outline: none;
  color: #ff7c30;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  font-weight: 500;
  background-color: transparent;
  text-align: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;

  &:hover {
    color: #ff9e30;
  }

  &:active {
    color: #ff7c30;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: #999999;
    cursor: not-allowed;
  }

  ${(props) =>
    props.primary &&
    css`
      color: white;
      background-color: #ff7c30;
   

      &:hover {
        background-color: #ff9e30;
        color: white;
      }

      &:active {
        color: white;
        --tw-ring-color: transparent;
        background-color: #ff7c30;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: #d8d8d8;
        color: #999999;
        cursor: not-allowed;
      }
    `};

  ${(props) =>
    props.secondary &&
    css`
      background-color: rgba(255, 124, 48, 0.1);
      color: #ff7c30;
     

      &:hover {
        background-color: #ff9e30;
        color: white;
     
      }

      &:active {
        color: #ff7c30;
        background-color: rgba(255, 124, 48, 0.1);
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: #d8d8d8;
        color: #999999;
        cursor: not-allowed;
      }
    `};

  ${(props) =>
    props.tertiary &&
    css`
      background-color: white;
      color: #555555;
      box-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      --tw-ring-color: #d8d8d8;

      &:hover {
        background-color: rgba(31, 45, 61, 0.1);
        color: #555555;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: #d8d8d8;
        color: #999999;
        cursor: not-allowed;
      }
    `};

     ${(props) =>
    props.green &&
    css`
      background-color: #EAFFF7;
      color: #1ACD67;
      box-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      --tw-ring-color: #EAFFF7;

      &:hover {
        background-color: #0FE491;
        color: #ffffff;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: #d8d8d8;
        color: #999999;
        cursor: not-allowed;
      }
    `};

     ${(props) =>
    props.red &&
    css`
      background-color: #FFEAEA;
      color: #FF4949;
      box-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      --tw-ring-color: #FFEAEA;

      &:hover {
        background-color: #F94343;
        color: #ffffff;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: #d8d8d8;
        color: #999999;
        cursor: not-allowed;
      }
    `};


  ${(props) =>
    props.border &&
    css`
      --tw-ring-color: #ff4949;
      box-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      color: #ff4949;

      &:hover {
        background-color: #ff4949;
        color: white;
      }

      &:active {
        background-color: #ff4949;
        --tw-ring-color: #ff4949;
        box-shadow: var(--tw-ring-inset) 0 0 0
          calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        color: white;
      }

      &:disabled {
        background-color: #d8d8d8;
        color: #999999;
        cursor: not-allowed;
        box-shadow: var(--tw-ring-inset) 0 0 0
          calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      }
    `};

  ${(props) =>
    props.icon &&
    css`
      flex: 1 1 0%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #fff2ea;
      color: #ff7c30;
  

      &:hover {
        background-color: #ff9e30;
        color: white;
      }

      &:active {
        background-color: #fff2ea;
        color: #ff7c30;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: #d8d8d8;
        color: #999999;
        cursor: not-allowed;
      }
    `};
  ${(props) =>
    props.basket &&
    css`
      flex: 1 1 0%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: white;
      color: #ff7c30;
      box-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      --tw-ring-color: #ff7c30;

      &:hover {
        background-color: #ff9e30;
        color: white;
        box-shadow: var(--tw-ring-inset) 0 0 0
          calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        --tw-ring-color: #ff7c30;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: #d8d8d8;
        color: #999999;
        cursor: not-allowed;
      }

      .amount {
        border-radius: 9999px;
        margin-left: 0.5rem;
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.125rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: #ff7c30;
        color: white;

        &:hover {
          background-color: white;
          color: #ff7c30;
        }

        &:disabled {
          background-color: white;
          color: #999999;
        }
      }

      &:hover {
        .amount {
          background-color: white;
          color: #ff7c30;
        }
      }
    `};

  ${(props) =>
    props.icon &&
    props.tertiary &&
    css`
      flex: 1 1 0%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: rgba(60, 72, 88, 0.05);
      color: #555555;
     

      &:hover {
        background-color: rgba(60, 72, 88, 0.1);
        color: #555555;
      }

      &:focus {
        outline: none;
      }

      &:active {
        background-color: rgba(60, 72, 88, 0.1);
        color: #555555;
      }

      &:disabled {
        background-color: #d8d8d8;
        color: #999999;
        cursor: not-allowed;
      }

      .amount {
        border-radius: 9999px;
        margin-left: 0.5rem;
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.125rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: #ff7c30;
        color: white;

        &:hover {
          background-color: white;
          color: #ff7c30;
        }

        &:disabled {
          background-color: white;
          color: #999999;
        }
      }

      &:hover {
        .amount {
          background-color: white;
          --tw-ring-color: #d8d8d8;
        }
      }
    `};

  ${(props) =>
    props.icon &&
    props.dark &&
    css`
      flex: 1 1 0%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #ff7c30;
      color: white;
      &:hover {
        background-color: #ff9e30;
        color: white;

      }

      &:focus {
        outline: none;
        background-color: #ff7c30;
      }

      &:active {
        background-color: rgba(255, 124, 48, 0.9);
      }

      &:disabled {
        background-color: #d8d8d8;
        color: #999999;
        cursor: not-allowed;
      }

      .amount {
        border-radius: 9999px;
        margin-left: 0.5rem;
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.125rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: white;
        color: #ff7c30;

        &:hover {
          background-color: #ff7c30;
          color: white;
        }

        &:disabled {
          background-color: white;
          color: #999999;
        }
      }

      &:hover {
        .amount {
          background-color: white;
          color: #ff7c30;
        }
      }
    `};

  svg {
    margin-top: 0.125rem;
    margin-right: 0;
    width: 1.25rem;
    height: 1.25rem;
  }

  span {
    margin-left: 0.375rem;
  }
`;

export default Button;
