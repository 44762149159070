import React, { lazy, Suspense, use, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "./components/Loading";
import FormLibrary from "pages/FormLibrary";
import Homepage from "pages/Dashboard/Homepage";
import NewProject from "pages/Dashboard/Projects/NewProject";
import Project from "pages/Dashboard/Projects/Project";
import Settings from "pages/Dashboard/Settings/Settings";
import MyBills from "pages/Dashboard/Accounting/Accounting";
import BillPay from "pages/Dashboard/Accounting/BillPay";
import TicketAndSupport from "pages/Dashboard/TicketAndSupport/TicketAndSupport";
import ComplaintNotificationDetails from "pages/Dashboard/TicketAndSupport/ComplaintNotificationDetails";
import TicketMessages from "pages/Dashboard/TicketAndSupport/TicketMessages";
import Services from "pages/Dashboard/Services/Services";
import DomainsDetails from "pages/Dashboard/Services/DomainsDetails";
import VirtualServerDetails from "pages/Dashboard/Services/VirtualServerDetails";
import PhysicalServerDetails from "pages/Dashboard/Services/PhysicalServerDetails";
import WebHostingDetails from "pages/Dashboard/Services/WebHostingDetails";
import MailHostingDetails from "pages/Dashboard/Services/MailHostingDetails";
import CoLocationDetails from "pages/Dashboard/Services/CoLocationDetails";
import SSLCertificatesDetails from "pages/Dashboard/Services/SSLCertificatesDetails";
import PaymentMessage from "pages/Dashboard/Accounting/PaymentMessage";
import { tokenCheck } from "services/allServices";
import { useAuthStore } from "store/AuthStore";
import WebLogin from "pages/Login/WebLogin";
import { PrivateRoute } from "./private.route";
import Notification from "pages/Dashboard/Notification/Notification";
import ControlPanelDetails from "pages/Dashboard/Services/ControlPanelDetails";
import { useGlobalStore } from "store/GlobalStore";
import CryptoJS from "crypto-js";
import CloudServerDetails from "pages/Dashboard/Services/CloudServersDetail";
import NotFound from "components/NotFound/NotFound";
import IPServiceDetail from "pages/Dashboard/Services/IPServiceDetail";
import SupportServiceDetail from "pages/Dashboard/Services/SupportServiceDetail";
import IYSServiceDetail from "pages/Dashboard/Services/IYSServiceDetail";
import FirewallServiceDetail from "pages/Dashboard/Services/FirewallServiceDetail";
import ProxyServiceDetail from "pages/Dashboard/Services/ProxyServiceDetail";
import NewRequestTickets from "components/TicketAndSupport/NewRequestTickets";

const LoginLayout = lazy(() => import("./layouts/LoginLayout"));
const DashboardLayout = lazy(() => import("./layouts/DashboardLayout"));
const SignIn = lazy(() => import("./pages/Login/SignIn"));
const ResetPassword = lazy(() => import("./pages/Login/ResetPassword"));

const RouteList = () => {
  const [isLoggin, setIsLoggin] = useAuthStore((state) => [
    state.isLoggin,
    state.setIsLoggin
  ]);
  const [permissions, setPermissions, isResponsible, setIsResponsible] = useGlobalStore(state => [state.permissions, state.setPermissions, state.isResponsible, state.setIsResponsible]);
  const encryptMessage = (message) => {
    let secretKey = process.env.REACT_APP_CRYPTO_KEY;
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(message), secretKey);
    return cipherText.toString();
  };
  const checkAuth = async () => {

    if (isLoggin) {
      if (localStorage.getItem("__mp_tk") !== null) {
        tokenCheck(localStorage.getItem("__mp_tk"))
          .then((res) => {
            if (!res.status) {
              setIsLoggin(false);
              window.location.pathname = "/login";
            } else {
              if (res?.permissions && res?.is_responsible) {
                localStorage.setItem("__u_per", encryptMessage(res?.permissions));
                setPermissions(res?.permissions);
              }
              localStorage.setItem("__u_is_resp", res?.is_responsible);
              setIsResponsible(res?.is_responsible);
            }
          })
          .catch((err) => {
            setIsLoggin(false);
            window.location.pathname = "/login";
          });
      } else {
        setIsLoggin(false);
        window.location.pathname = "/login";
      }
    }

  };

  useEffect(() => {
      checkAuth();
  }, []);
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route element={<LoginLayout />}>
            <Route path="/login" element={<SignIn />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/weblogin" element={<WebLogin />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<DashboardLayout />}>
              <Route path="/" element={<Homepage />} />
              <Route path="/newproject" element={<NewProject />} />
              <Route path="/project/:projectName" element={<Project />} />
              <Route path="/project/:projectName/:url" element={<Project />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/:url" element={<Settings />} />
              <Route path="/formlibrary" element={<FormLibrary />} />
              <Route path="/accounting" element={<MyBills />} />
              <Route path="/accounting/:url" element={<MyBills />} />
              <Route path="/bill-pay" element={<BillPay />} />
              <Route path="/payment/:status/:bank/:orderno" element={<PaymentMessage />} />
              <Route path="/ticket-and-support" element={<TicketAndSupport />} />
              <Route path="/ticket-and-support/:url" element={<TicketAndSupport />} />
              <Route path="/ticket-and-support/new-request-ticket" element={<NewRequestTickets />} />
              <Route path="/ticket-and-support/request-tickets/:id" element={<NewRequestTickets />} />
              <Route path="/complaint-notification-details/:id" element={<ComplaintNotificationDetails />} />
              <Route path="/ticket-and-support/ticket-messages" element={<TicketMessages />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services/:url" element={<Services />} />
              {(!isResponsible || (isResponsible && (permissions.length === 0 || permissions?.find((item) => item.id === "domains" && item.checked === true)?.checked))) &&
                <Route path="/services/domain/:id" element={<DomainsDetails />} />
              }
              {(!isResponsible || (isResponsible && (permissions.length === 0 || permissions?.find((item) => item.id === "virtual-servers" && item.checked === true)?.checked))) &&
                <>
                  <Route path="/services/virtual-server/:id" element={<VirtualServerDetails />} />
                  <Route path="/services/virtual-server/:id/:url" element={<VirtualServerDetails />} />
                </>
              }
              {(!isResponsible || (isResponsible && (permissions.length === 0 || permissions?.find((item) => item.id === "cloud-servers" && item.checked === true)?.checked))) &&
                <>
                  <Route path="/services/cloud-server/:id" element={<CloudServerDetails />} />
                  <Route path="/services/cloud-server/:id/:url" element={<CloudServerDetails />} />
                </>
              }
              {(!isResponsible || (isResponsible && (permissions.length === 0 || permissions?.find((item) => item.id === "dedicated-servers" && item.checked === true)?.checked))) &&
                <>
                  <Route path="/services/dedicated-server/:id" element={<PhysicalServerDetails />} />
                  <Route path="/services/dedicated-server/:id/:url" element={<PhysicalServerDetails />} />
                </>
              }
              {(!isResponsible || (isResponsible && (permissions.length === 0 || permissions?.find((item) => item.id === "colocation" && item.checked === true)?.checked))) &&
                <>
                  <Route path="/services/co-location/:id" element={<CoLocationDetails />} />
                  <Route path="/services/co-location/:id/:url" element={<CoLocationDetails />} />
                </>
              }
              {(!isResponsible || (isResponsible && (permissions.length === 0 || permissions?.find((item) => item.id === "web-hosting" && item.checked === true)?.checked))) &&
                <Route path="/services/web-hosting/:id" element={<WebHostingDetails />} />

              }
              {(!isResponsible || (isResponsible && (permissions.length === 0 || permissions?.find((item) => item.id === "mail-hosting" && item.checked === true)?.checked))) &&
                <Route path="/services/mail-hosting/:id" element={<MailHostingDetails />} />

              }
              {(!isResponsible || (isResponsible && (permissions.length === 0 || permissions?.find((item) => item.id === "ssl-certificates" && item.checked === true)?.checked))) &&
                <Route path="/services/ssl-certificate/:id" element={<SSLCertificatesDetails />} />

              }
              {(!isResponsible || (isResponsible && (permissions.length === 0 || permissions?.find((item) => item.id === "control-panel" && item.checked === true)?.checked))) &&
                <Route path="/services/control-panel/:id" element={<ControlPanelDetails />} />
              }

              {/* BURAYA IP DETAYLARI EKLİCEN */}
              {
                <Route path="/services/ip/:id" element={<IPServiceDetail />} />
              }
              {
                <Route path="/services/support/:id" element={<SupportServiceDetail />} />
              }
              {
                <Route path="/services/iys/:id" element={<IYSServiceDetail />} />
              }
              {
                <Route path="/services/iys/:id/:url" element={<IYSServiceDetail />} />
              }
              {
                <Route path="/services/firewall/:id" element={<FirewallServiceDetail />} />
              }
              {
                <Route path="services/proxy/:id" element={<ProxyServiceDetail />} />
              }

              {(!isResponsible || (isResponsible && (permissions.length === 0 || permissions?.find((item) => item.id === "notifications" && item.checked === true)?.checked))) &&
                <Route path="/notification" element={<Notification />} />
              }
              {/*<Route path="/dashboard" element={<Home />} />*/}
              <Route element={<NotFound />} path={"*"} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default RouteList;
