import Button from "components/FormElements/Button";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { createAddMultipleEntryIYS } from "services/allServices";

const AddMultipleEntry = ({ setOpenAddEntryModal }) => {
    const { t } = useTranslation(["global", "services"]);
    const { addToast } = useToasts();
    const [file, setFile] = useState(null);

    const supportedFileTypes = [
        "application/zip", // ZIP dosyaları
        "application/vnd.ms-excel", // Excel (.xls)
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel (.xlsx)
        "text/csv", // CSV
    ];

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            addToast("Dosya seçilmedi.", { appearance: "error", autoDismiss: true });
            return;
        }

        if (!supportedFileTypes.includes(selectedFile.type)) {
            setFile(null);
            addToast("Yalnızca ZIP, Excel veya CSV dosyaları yükleyebilirsiniz.", {
                appearance: "error",
                autoDismiss: true,
            });
            return;
        }

        setFile(selectedFile);
        addToast("Dosya başarıyla seçildi.", { appearance: "success", autoDismiss: true });
    };

    const handleUpload = async () => {
        if (!file) {
            addToast("Lütfen bir dosya seçin.", { appearance: "error", autoDismiss: true });
            return;
        }

        let params = new FormData();
        params.append("file", file);

        await createAddMultipleEntryIYS(params).then(res => {
            if (res.status === "multiple_data_added") {
                addToast("Dosya başarıyla yüklendi!", { appearance: "success", autoDismiss: true });
                setOpenAddEntryModal(false);
            } else {
                addToast("Dosya yüklenirken bir hata oluştu.", { appearance: "error", autoDismiss: true });
            }
        })
    };

    const handleRemoveFile = () => {
        setFile(null);
        addToast("Dosya silindi.", { appearance: "info", autoDismiss: true });
    };

    return (
        <div className="max-w-lg p-6 mx-auto bg-white rounded-lg shadow-md">
            {!file ? (
                <div className="mb-4 cursor-pointer">
                    <input
                        type="file"
                        accept=".zip,.xls,.xlsx,.csv"
                        onChange={handleFileChange}
                        className="block w-full text-sm text-gray-500 cursor-pointer file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary file:cursor-pointer file:text-white hover:file:bg-orange-400"
                    />
                </div>
            ) : (
                <div className="flex items-center justify-between p-4 mb-4 border rounded-lg bg-gray-50">
                    <span className="text-sm text-gray-700">{file?.name}</span>
                    <button
                        onClick={handleRemoveFile}
                        className="px-3 py-1 text-sm font-medium text-white bg-red-500 rounded hover:bg-red-600"
                    >
                        < i className="text-lg ri-delete-bin-line"></i>
                    </button>
                </div>
            )}
            <Button
                onClick={handleUpload}
                className="w-full px-4 py-2 text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                disabled={!file}
                primary
            >
                {t("services:upload")}
            </Button>
        </div>
    );
};

export default AddMultipleEntry;