import React, { useEffect, useState } from "react";
import { useGlobalStore } from "store/GlobalStore";
import FastTransactions from "components/Services/MailHosting/FastTransactions";
import { Button } from "components";
import BuyBackupModal from "components/Services/ServicesGlobalModals/BuyBackupModal";
import BackupServicesModal from "components/Services/ServicesGlobalModals/BackupServicesModal";
import FormatModal from "components/Services/ServicesGlobalModals/FormatModal";
import MailUpgradeServicesModal from "components/Services/MailHosting/Modals/MailUpgradeServicesModal";
import { useNavigate, useParams } from "react-router";
import { getMailServer } from "services/allServices";
import { useTranslation } from "react-i18next";
import NewSupportRequestModal from "components/TicketAndSupport/TicketAndSupportModals/NewSupportRequestModal";

function MailHostingDetails() {
  const { t } = useTranslation(["support", "global", "projects", "services"]);
  const [selectedPageTab, setSelectedPageTab, setOpensupportRequest, opensupportRequest] = useGlobalStore((state) => [
    state.selectedPageTab,
    state.setSelectedPageTab,
    state.setOpensupportRequest,
    state.opensupportRequest
  ]);
  const [serverStart, setServerStart] = useState(false);
  const [openBuyBackupModal, setOpenBuyBackupModal] = useState(false);
  const [openBackupServicesModal, setOpenBackupServicesModal] = useState(false);
  const [openUpgradeServicesModal, setOpenUpgradeServicesModal] = useState(
    false
  );
  const [openFormatModal, setOpenFormatModal] = useState(false);
  const [openExtendServiceModal, setOpenExtendServiceModal] = useState(false);


  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  let { id } = useParams();
  const [pageData, setPageData] = useState();

  const onGetPageData = () => {
    setIsLoading(true);
    getMailServer(id).then(res => {
      setIsLoading(false);
      setPageData(res);
      if (!res) {
        navigate("/");
      }
    });
  };

  useEffect(() => {
    onGetPageData();
  }, [id]);

  useEffect(() => {
    if (parseInt(selectedPageTab) > 2) {
      setSelectedPageTab("1");
    }
  }, [selectedPageTab]);
  return (
    (pageData && pageData?.status !== "waiting") ?
      <div className="container relative w-full">
        <div className="justify-between items-center p-4 bg-white rounded-md shadow-sm lg:flex">
          <div>
            <h2 className="font-semibold">{pageData?.product_name}</h2>
            <p className="text-sm text-passive">ID #{pageData?.id}</p>
          </div>
          <div className="items-center lg:flex">
            <div className="items-center lg:flex">
              <div className="flex items-center">
                <div className="flex items-center mr-4">
                  <i className="mr-1 text-3xl ri-flashlight-fill text-passive" />
                  <p className="text-sm text-passive">{t("global:active")}</p>
                </div>

                <div
                  // onClick={() => setOpenBuyBackupModal(true)}
                  // onClick={() => setOpenBackupServicesModal(true)}
                  className="flex items-center mr-4 cursor-pointer group"
                >
                  <i
                    className="mr-1 text-3xl transition-all duration-300 ri-database-2-fill text-passive group-hover:text-passive" />
                  <p className="text-sm transition-all duration-300 text-passive group-hover:text-passive">
                  {t("services:backup")}
                  </p>
                </div>
              </div>
              <div className="items-center lg:flex">
                <div className="flex items-center">
                  <div
                    //onClick={() => setOpenUpgradeServicesModal(true)}
                    className="flex items-center mr-4 cursor-pointer group"
                  >
                    <i
                      className="mr-1 text-3xl transition-all duration-300 ri-stack-fill text-passive group-hover:text-passive" />
                    <p className="text-sm transition-all duration-300 text-passive group-hover:text-passive">
                      {t("services:upgrade")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Button
              onClick={() => setOpensupportRequest(true)}
              className="flex items-center mr-2 whitespace-nowrap"
              secondary
              icon
              dark
            >
              <i className="ri-folder-add-line" />
              <span>{t("support:newTicket")}</span>
            </Button>
            <Button
              // onClick={() => setOpenExtendServiceModal(true)}
              className="flex items-center px-20 mt-3 lg:mt-0"
              secondary
              icon
              disabled
            >
              <i className="ri-history-line" />
              <span className="whitespace-nowrap">{t("services:extendService")}</span>
            </Button>
            <Button disabled className="flex items-center px-8 mt-3 ml-4 lg:mt-0" secondary icon dark>
              <i className="ri-share-box-line" />
              <span>{t("services:GoToPanel")}</span>
            </Button>
          </div>
        </div>

        {/* <div className="mt-4">
          <Tabs tabs={tabs} />
        </div> */}

        {/* {selectedPageTab === "1" && (
          <> */}
        <FastTransactions data={pageData} onGetPageData={onGetPageData} />
        {/* </>
        )} */}
        {/* {selectedPageTab === "2" && (
          <>
            <MailAccounts data={pageData} />
          </>
        )} */}

        {openBuyBackupModal && (
          <BuyBackupModal setOpenModal={() => setOpenBuyBackupModal(false)} />
        )}
        {openUpgradeServicesModal && (
          <MailUpgradeServicesModal
            setOpenModal={() => setOpenUpgradeServicesModal(false)}
          />
        )}
        {openBackupServicesModal && (
          <BackupServicesModal
            setOpenModal={() => setOpenBackupServicesModal(false)}
          />
        )}
        {openFormatModal && (
          <FormatModal setOpenModal={() => setOpenFormatModal(false)} />
        )}
        {opensupportRequest && (
          <NewSupportRequestModal
            setOpenModal={() => setOpensupportRequest(false)}
            defaultProduct={pageData.id}
          />
        )}
      </div>
      :
      <div className="flex flex-col justify-center items-center mt-20">
        <img src="/servicesWaiting.svg" width="352" height="330" />
        <p className="mt-3 text-5xl font-semibold text-primary-hover">{t("services:preparedService")}</p>
        <p className="my-2">{t("services:preparedServiceDesc1")}</p>
        <p>{t("services:preparedServiceDesc1")}</p>
      </div>
  );
}

export default MailHostingDetails;
