import React, { useEffect, useState } from "react";
import Button from "components/FormElements/Button";
import { useTranslation } from "react-i18next";
import VirtualIPTable from "./Tables/VirtualIPTable";
import AddNoteModal from "components/Modals/AddNoteModal";
import VirtualIPDetailsModal from "./Modals/VirtualIPDetailsModal";
import VirtualIPFilterModal from "./Modals/VirtualIPFilterModal";
import { listOwnIPClass, listOwnIPClassOld, updateIPAddress } from "services/allServices";
import { useToasts } from "react-toast-notifications";
import { useGlobalStore } from "store/GlobalStore";

function VirtualIPRecords({ data }) {
  const { t, i18n } = useTranslation(["projects", "global", "services", "nav"]);
  const { addToast } = useToasts();
  const [openModal, setOpenModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openIPDetailsModal, setOpenIPDetailsModal] = useState(false);

  const [ipPublicList, setIPPublicList] = useState(false);
  const [ipPrivateList, setIPPrivateList] = useState(false);

  const [ipRecordsFilterData, setIPRecordsFilterData] = useGlobalStore((state) => [state.ipRecordsFilterData, state.setIPRecordsFilterData]);


  const onListIP = async (configure) => {
    if (data.status === "closed") {
      listOwnIPClassOld(data?.product_type_id, data?.id).then(res => {
        setIPPrivateList(res.customer_owned_class_records);
        setIPPublicList(res?.customer_owned_ip_records);
      })
    } else {
      await listOwnIPClass(data?.product_type_id, data?.id, configure ? configure : ipRecordsFilterData).then(res => {
        let privateList = []
        let publicList = []
        res.customer_owned_class_records.map((item) => {
          if (item.is_public) {
            publicList.push(item);
          } else {
            privateList.push(item);
          }
        })
        res.customer_owned_ip_records.map((item) => {
          if (item.is_public) {
            publicList.push(item);
          } else {
            privateList.push(item);
          }
        })
        setIPPrivateList(privateList);
        setIPPublicList(publicList);
      });
    }

  };


  const onUpdateIP = (note) => {
    let params = {
      content: {
        note: note
      }
    };
    updateIPAddress(openNoteModal.id, params).then(res => {
      addToast(t("global:itSaved"), {
        appearance: "success",
        autoDismiss: true
      });
      onListIP();
      setOpenNoteModal(false);
    });
  };


  useEffect(() => {
    const asyncFunction = async () => {
      let configureFilter = {
        ip_address: null,
        subnet: null
      };

      if (!ipRecordsFilterData || ipRecordsFilterData == "") {
        await setIPRecordsFilterData(configureFilter);
        await onListIP(configureFilter);

      } else {
        await onListIP(ipRecordsFilterData);
      }


    };
    asyncFunction();
  }, [data]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="my-5 font-semibold">{t("nav:ip-records")}</h2>
        {data.status !== "closed" &&
          <div className="relative">
            <Button
              onClick={() => setOpenModal(true)}
              className="flex items-center px-7"
              tertiary
            >
              <i className="ri-equalizer-line" />
              <span>{t("projects:filter")}</span>
            </Button>
            {(ipRecordsFilterData?.count && ipRecordsFilterData?.count > 0) ?
              <div
                className="flex absolute -top-1 -right-1 justify-center items-center w-5 h-5 text-sm text-center text-white rounded-full bg-primary-hover">
                {ipRecordsFilterData?.count}
              </div>
              :
              <></>
            }
          </div>
        }

      </div>
      <div className="overflow-x-auto">
        {ipPublicList && ipPublicList.length > 0 &&
          <div className="mb-10">
            <h3 className="my-3 font-semibold">Public IP</h3>
            <VirtualIPTable
              ipList={ipPublicList}
              setOpenNoteModal={setOpenNoteModal}
              setOpenIPDetailsModal={(v) => setOpenIPDetailsModal(v)}
            />
          </div>

        }

        {ipPrivateList && ipPrivateList.length > 0 &&
          <div>
            <h3 className="my-3 font-semibold">Private IP</h3>
            <VirtualIPTable
              ipList={ipPrivateList}
              setOpenNoteModal={setOpenNoteModal}
              setOpenIPDetailsModal={(v) => setOpenIPDetailsModal(v)}
            />
          </div>

        }

        {ipPrivateList.length === 0 && ipPublicList.length === 0 &&

          <div className="flex flex-col justify-center items-center my-10 lg:my-20">
            <img src="/servicesOther.svg" width="403" height="339" />
            <h1>{t("services:NoAssignedIp")}</h1>
          </div>

        }
      </div>

      {openModal && (
        <VirtualIPFilterModal onClick={onListIP}
          ipRecordsFilterData={ipRecordsFilterData}
          setIPRecordsFilterData={setIPRecordsFilterData}
          setOpenModal={(value) => setOpenModal(value)} />
      )}
      {openNoteModal && (
        <AddNoteModal data={openNoteModal?.note} onUpdateNote={onUpdateIP}
          setOpenModal={(value) => setOpenNoteModal(value)} />
      )}
      {openIPDetailsModal && (
        <VirtualIPDetailsModal
          data={openIPDetailsModal}
          setOpenModal={(value) => setOpenIPDetailsModal(value)}
          onListIP={onListIP}

        />
      )}
    </div>
  );
}

export default VirtualIPRecords;
