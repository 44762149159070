import FileUploader from "components/FileUploader";
import Button from "components/FormElements/Button";
import ComboBox from "components/FormElements/ComboBox";
import TextArea from "components/FormElements/Input/TextArea";
import TextField from "components/FormElements/Input/TextField";
import useConfigurationComboBox, { useComboBox } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { createRequestTicket, getTicketCategoriesList, getTicketDepartmentsList, getTicketOwnedProductList, requestDetail, ticketAddFile, uploadFileRequestTicket } from "services/allServices";
import { useGlobalStore } from "store/GlobalStore";

const NewRequestTickets = () => {
    const { t } = useTranslation(["support", "global"]);
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const params = useParams();

    const [permissions] = useGlobalStore(state => [state.permissions]);

    const [selectedDep, depList, changeSelectedDep, setDepList] = useComboBox(t("support:selectDepartment"));
    const [selectedCat, catList, changeSelectedCat, setCatList] = useComboBox(t("support:selectCategory"));
    const [selectedProduct, productList, changeSelectedProduct, setProductList] = useConfigurationComboBox(t("support:selectService"));
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [file, uploadFile] = useState();
    const [loading, setLoading] = useState(false);

    const onSelectedDep = (val) => {
        getTicketCategoriesList(val.id).then((cats) => {
            changeSelectedCat(0, t("support:selectCategory"));
            setCatList(cats, "id", "ticket_categories");
        });
    };

    const translateDepartmentName = (name, lang) => {
        if (lang === 'tr') return name;
        switch (name) {
            case 'Muhasebe':
                return 'Accounting';
            case 'Teknik':
                return 'Technical';
            case 'Satış':
                return 'Sales';
            default:
                return name;
        }
    };

    const handleSubmit = () => {
        if (subject === "") {
            addToast(t("support:enterSubject"), { appearance: "error", autoDismiss: true });
            return;
        }

        if (message === "") {
            addToast(t("support:enterMessage"), { appearance: "error", autoDismiss: true });
            return;
        }
        setLoading(true)

        let requestData = {
            department_id: selectedDep.id,
            category_id: selectedCat.id,
            related_product_id: selectedProduct.otherInfo.owned_product_id ? selectedProduct.otherInfo.owned_product_id : 0,
            product_type_id: selectedProduct?.otherInfo?.type_group_info?.type_id ? selectedProduct?.otherInfo?.type_group_info?.type_id : 0,
            product_name: selectedProduct?.id ? selectedProduct.text : "",
            title: subject,
            message: message,
            img: ""
        }

        if (file && file.length > 0) {
            let params = new FormData();
            params.append("file", file[0]);
            uploadFileRequestTicket(params).then(async (res) => {
                if (res.message === "successfully") {
                    requestData.img = res.file_path_https;
                    await createRequestTicket(requestData).then((data) => {
                        if (data.status === "created") {
                            setLoading(false)
                            addToast(t("support:ticketCreated"), { appearance: "success", autoDismiss: true });
                            navigate("/ticket-and-support/request-tickets");
                        }
                    })
                } else {
                    setLoading(false)
                    addToast(t("support:fileUploadError"), { appearance: "error", autoDismiss: true });
                }
            });
        } else {
            createRequestTicket(requestData).then((data) => {
                if (data.status === "created") {
                    setLoading(false)
                    addToast(t("support:ticketCreated"), { appearance: "success", autoDismiss: true });
                    navigate("/ticket-and-support/request-tickets");
                } else {
                    setLoading(false)
                    addToast(t("support:ticketNotCreated"), { appearance: "error", autoDismiss: true });
                }
            });
        }
    }

    useEffect(() => {

        if (params.id) {
            requestDetail(params.id).then((data) => {
                setSubject(data.title);
                setMessage(data.message);
                changeSelectedDep(data.department_id, data.department_name);
                changeSelectedCat(data.category_id, data.category);
                changeSelectedProduct(data.related_product_id, data.product_name);
            })

        } else {
            const lang = localStorage.getItem('ln');

            getTicketDepartmentsList().then((deps) => {
                if (permissions?.length > 0) {
                    let newList = [];
                    deps?.map((item) => {
                        const translatedName = translateDepartmentName(item.department_name, lang);
                        if (translatedName === "Muhasebe" && permissions?.find((item) => item.id === "support-request-accounting")?.checked === true) {
                            newList.push({ ...item, department_name: translatedName })
                        }
                        if (translatedName === "Teknik" && permissions?.find((item) => item.id === "support-request-tech")?.checked === true) {
                            newList.push({ ...item, department_name: translatedName })
                        }
                        if (translatedName === "Satış" && permissions?.find((item) => item.id === "support-request-sales")?.checked === true) {
                            newList.push({ ...item, department_name: translatedName })
                        }
                    })
                    setDepList(newList, "id", "department_name");
                } else {
                    const translatedDeps = deps.map((item) => ({
                        ...item,
                        department_name: translateDepartmentName(item.department_name, lang),
                    }));

                    setDepList(translatedDeps, "id", "department_name");
                }
            });

            getTicketOwnedProductList().then((products) => {
                setProductList(products, "owned_product_id", "product_name", products);
            });
        }
    }, [])


    return (
        loading ?
            <div className="flex justify-center items-center mt-20 w-full">
                <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                    type="Oval"
                    color="#ff7c30"
                    height={75}
                    width={75}
                    timeout={3000} //3 secs
                />
            </div>
            : <div>
                <div className="w-1/2">
                    <h2 className="my-5 font-semibold">{params.id ? t("support:requestDetail") : t("support:newRequestTicket")}</h2>
                    {params.id ? <></> : <p className="font-semibold">{t("support:newTicketRequestDesc")}</p>}
                </div>

                <div className="grid grid-cols-3 gap-8 pt-8 w-1/2">
                    <ComboBox
                        id="department"
                        name="department"
                        selected={selectedDep}
                        setSelected={changeSelectedDep}
                        list={depList}
                        onSelected={(v) => onSelectedDep(v)}
                        label={t("global:department")}
                        isSearchable={false}
                        disabled={params.id ? true : false}
                    />

                    <ComboBox
                        id="category"
                        name="category"
                        selected={selectedCat}
                        setSelected={changeSelectedCat}
                        list={catList}
                        label={t("support:category")}
                        isSearchable={false}
                        disabled={params.id ? true : false}
                    />

                    <ComboBox
                        id="hizmet"
                        name="hizmet"
                        selected={selectedProduct}
                        setSelected={changeSelectedProduct}
                        list={productList}
                        label={t("global:associatedService")}
                        isSearchable={true}
                        disabled={params.id ? true : false}
                    />
                </div>

                <div className="py-4 space-y-4 w-1/2">
                    <TextField
                        id="title"
                        name="title"
                        type="text"
                        label={t("support:subjectTitle")}
                        value={subject}
                        onChange={setSubject}
                        disabled={params.id ? true : false}
                    />

                    <TextArea
                        id="message"
                        name="message"
                        label={t("support:requestMessage")}
                        placeholder={t("support:requestMessage")}
                        rows="4"
                        value={message}
                        onChange={setMessage}
                        disabled={params.id ? true : false}
                    />

                    <FileUploader
                        id="download1"
                        file="fileUpload1"
                        name="download1"
                        uploadId="uploadId1"
                        value={file}
                        defaultText="Dosya Seçiniz"
                        required="required"
                        setValue={uploadFile}
                        label={t("support:uploadFile")}
                        placeholder={t("support:uploadFile")}
                        inputStyle="w-full py-0 text-xs"
                        disabled={params.id ? true : false}
                    />
                </div>

                {params.id ?
                    <></>
                    : <div className="flex justify-end items-end w-1/2">
                        <Button
                            onClick={() => navigate("/ticket-and-support/request-tickets")}
                            className="px-12 mt-3 mr-0 w-full lg:mt-0 lg:mr-5 lg:w-auto"
                            tertiary
                        >
                            {t("global:giveUp")}
                        </Button>
                        <Button className="px-12 mr-0 w-full lg:w-auto"
                            onClick={() => handleSubmit()}
                            primary>
                            {t("global:save")}
                        </Button>
                    </div>}
            </div>
    )
}

export default NewRequestTickets