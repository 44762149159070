import Button from "components/FormElements/Button"
import { useTranslation } from "react-i18next";

const IYSMessageModal = ({ setPermissionHistoryModal }) => {
    const { t } = useTranslation(["global", "services"]);


    return (
        <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                ></span>
                <div
                    className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-modalBg">
                        <div className="flex justify-end px-2 py-1">
                            <i
                                onClick={() => setPermissionHistoryModal(false)}
                                className="text-2xl cursor-pointer ri-close-line text-passive"
                            ></i>
                        </div>

                        <div className="flex flex-col items-center px-5 pt-4 pb-10 bg-modalBg">
                            <div className="space-y-2.5 text-center">
                                <p>(+90) 555 555 55 55</p>
                                <p>Son İşlemler</p>
                            </div>
                            {/* <h2 className="my-1 font-semibold text-alert-red">{title}</h2> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IYSMessageModal