import Button from "components/FormElements/Button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RequestTicketTable from "./TicketAndSupportTables/RequestTicketTable";
import { useGlobalStore } from "store/GlobalStore";
import { useLocation, useNavigate } from "react-router";
import { TailSpin } from "react-loader-spinner";
import { listRequestTicket } from "services/allServices";

const RequestTickets = () => {
    const { t } = useTranslation(["support", "global"]);
    const navigate = useNavigate();

    const [requestList, setRequestList] = useState();
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [pageLimit, ticketFilterData, setTicketFilterData] = useGlobalStore((state) => [state.pageLimit, state.ticketFilterData, state.setTicketFilterData]);
    const location = useLocation();

    const onGetRequestTicketList = (page, limit) => {
        listRequestTicket(page, limit).then((data) => {
            if (data?.status !== "ticket_not_found") {
                setRequestList(data.request_list);
                setNumberOfPages(data[0]?.number_of_pages);
            } else if (data?.status === "ticket_not_found") {
                setRequestList("ticket_not_found");
            }
        });
    };

    const handleNewRequestTicket = () => {
        navigate("/ticket-and-support/new-request-ticket");
    }

    useEffect(() => {

        const asyncFunction = async () => {
            let page = 1;
            if (location?.search) {
                page = location.search.split("=")[1];
            }
            onGetRequestTicketList(page, pageLimit);
        };

        asyncFunction();
    }, [location]);

    return (
        <div>
            <div className="items-center justify-between lg:flex">
                <h2 className="my-5 font-semibold">{t("support:requestTickets")}</h2>
                <div>
                    <Button
                        onClick={() => handleNewRequestTicket()}
                        className="flex items-center px-7"
                        secondary
                        icon
                        dark
                    >
                        <i className="ri-chat-new-line" />
                        <span>{t("support:newRequestTicket")}</span>
                    </Button>
                </div>
            </div>

            <div className="overflow-x-auto">
                {requestList && requestList !== "ticket_not_found" ? (
                    <RequestTicketTable requestList={requestList} onGetRequestTicketList={onGetRequestTicketList} numberOfPages={numberOfPages}
                    />
                ) : requestList === "ticket_not_found" ? (
                    <div className="flex flex-col items-center justify-center mx-16 my-10 text-center lg:my-20">
                        <img src="/notFoundRequestTicket.svg" width="572" height="288" />
                        <h1 className="mt-6"> {t("support:notFountRequestTicket")}</h1>
                        <h5>{t("support:notFountRequestTicketDesc")}</h5>
                    </div>
                ) : (
                    <div className="flex items-center justify-center w-full mt-20">
                        <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                            type="Oval"
                            color="#ff7c30"
                            height={75}
                            width={75}
                            timeout={3000} //3 secs
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default RequestTickets