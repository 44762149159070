import React from "react";
import { useWindowSize } from "hooks";

function ModalsTabs({ tabs, selectedPageTab, setSelectedPageTab }) {
  const size = useWindowSize();
  return (
    <>
      {size.width > 900 ? (
        <div className="w-full flex pb-1 border-b-2 border-primary-disabled ">
          {tabs.map((tab, index) => {
            return (
              <div className="" key={index}>
                <div
                  onClick={() => setSelectedPageTab(tab.id)}
                  className={
                    selectedPageTab === tab.id
                      ? "mr-5 pb-2 relative cursor-pointer font-bold text-title"
                      : "mr-5 pb-2 relative cursor-pointer font-bold text-passive"
                  }
                >
                  {tab.name}
                  {selectedPageTab === tab.id && (
                    <div className="w-full py-0.5 absolute -bottom-1.5 rounded-xl bg-primary-hover"></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className="mobileTabsScroll w-full flex pb-1 border-b-2 border-primary-disabled overflow-x-scroll overflow-y-hidden">
          {tabs.map((tab, index) => {
            return (
              <div className="flex-none" key={index}>
                <div
                  onClick={() => setSelectedPageTab(tab.id)}
                  className={
                    selectedPageTab === tab.id
                      ? "mr-5 pb-2 relative cursor-pointer font-bold text-title"
                      : "mr-5 pb-2 relative cursor-pointer font-bold text-passive"
                  }
                >
                  {tab.name}
                  {selectedPageTab === tab.id && (
                    <div className="w-full py-0.5 absolute -bottom-1.5 rounded-xl bg-primary-hover"></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default ModalsTabs;
