import { useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useGlobalStore } from "store/GlobalStore";
import MakdosTable from "components/MakdosTable";

const IYSCallPermissionTable = ({ data, setData, onListPermissionsCall, numberOfPages }) => {
    const { t, i18n } = useTranslation(["services", "global"]);

    const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);
    const pageLimits = [10, 25, 50, 100];
    const [activePageSize, setActivePageSize] = useState(pageLimit);

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: t("services:recipient"),
            accessor: "iys_recipient"
        },
        {
            Header: t("services:status"),
            accessor: "iys_status",
            Cell: ({ value }) => {
                return value === "ONAY" ? <div className="text-green-500">
                    <i className="text-green-500 ri-check-line"></i> Onay
                </div> :
                    <div className="text-red-500">
                        <i className="text-red-500 ri-close-line"></i> Ret
                    </div>;
            }
        },
        {
            Header: t("services:permissionHistory"),
            accessor: "",
            // Burada history_list dönecek ona göre modal açılıp verielr listelenecek ona göre ilerlersin.
            // Cell: ({ value }) => {
            //     return value === true ? <div className="text-green-500 ">
            //         <i className="text-center text-green-500 cursor-pointer ri-history-line" onClick={() => setPermissionHistoryModal(true)}></i>
            //     </div> :
            //         <></>;
            // }
        },
        {
            Header: t("services:permissionDate"),
            accessor: "iys_consentDate",
            Cell: ({ value }) => {
                return moment(value).format("DD-MM-YYYY HH:mm");
            }
        },
        {
            Header: t("services:dateAdded"),
            accessor: "operation_time",
            Cell: ({ value }) => {
                return moment(value).format("DD-MM-YYYY HH:mm");
            }
        },
    ], []);

    return (
        <div>
            {data && data !== "ticket_not_found" &&
                <div className="w-full mb-12 ">
                    <MakdosTable
                        isCheckboxVisible={false}
                        setCheckList={() => {
                        }}
                        checkList={[]}
                        data={data}
                        setData={setData}
                        showPagination={true}
                        columns={columns}
                        pageLimits={pageLimits}
                        isBackendPagination={true}
                        backendPaginationMethod={(page, limit) => {
                            onListPermissionsCall(page, limit);
                        }}
                        backendNumberOfPages={numberOfPages}
                        activePageSize={activePageSize}
                        setActivePageSize={setActivePageSize}
                        isDndAvailable={false}
                    />
                </div>
            }
        </div>
    )
}

export default IYSCallPermissionTable