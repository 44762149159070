import React from "react";
import { useTranslation } from "react-i18next";
import Tabs from "components/Tabs";
import SupportRequest from "components/TicketAndSupport/SupportRequest";
import ComplaintNotification from "components/TicketAndSupport/ComplaintNotification";
import InformationCenter from "components/TicketAndSupport/InformationCenter";
import { useGlobalStore } from "store/GlobalStore";
import { useNavigate, useParams } from "react-router";
import { isNullOrEmptyOrUndefined } from "utils/common";
import AllNotification from "components/TicketAndSupport/AllNotification";
import RequestTickets from "components/TicketAndSupport/RequestTickets";

function TicketAndSupport() {
  const { t } = useTranslation(["support", "global"]);
  const [isResponsible, permissions] = useGlobalStore((state) => [state.isResponsible, state.permissions]);
  let slug = useParams();
  let navigate = useNavigate();

  const tabs = [
    {
      name: t("support:mySupportReguests"),
      id: "1",
      component: <SupportRequest />,
      slug: "",
      visible: true
    },
    {
      name: t("support:allNotification"),
      id: "2",
      component: <AllNotification />,
      slug: "all-notifications",
      visible: true
    },
    {
      name: t("support:complaintNotification"),
      id: "3",
      component: <ComplaintNotification />,
      slug: "complaint-notifications",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "complaint-notifications" && item.checked === true)?.checked) : true
    },
    {
      name: t("support:informationCenter"),
      id: "4",
      component: <InformationCenter />,
      slug: "information-center",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "information-center" && item.checked === true)?.checked) : true
    },
    {
      name: t("support:requestTickets"),
      id: "5",
      component: <RequestTickets />,
      slug: "request-tickets",
      visible: true
      // visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "request-tickets" && item.checked === true)?.checked) : true
    }

  ];

  return (
    <div className="container relative w-full ">
      <Tabs
        tabs={tabs.filter((x) => x.visible)}
        selectedTab={
          !isNullOrEmptyOrUndefined(slug.url)
            ? tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.id ?? tabs.filter((x) => x.visible)[0]?.id
            : tabs.filter((x) => x.visible)[0]?.id
        }
        setSelectedTab={(v) => {
          navigate(
            "/ticket-and-support/" +
            tabs.find((x) => x.id === v).slug
          );
        }}
      />


      <div className="mt-6">
        {!isNullOrEmptyOrUndefined(slug.url)
          ? (tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.component) ?? tabs.filter((x) => x.visible)[0]?.component
          : tabs.filter((x) => x.visible)[0]?.component
        }
      </div>
    </div>
  );
}

export default TicketAndSupport;
