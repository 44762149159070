import Button from "components/FormElements/Button"
import TextField from "components/FormElements/Input/TextField";
import { useTranslation } from "react-i18next";
import IYSCallPermissionTable from "../Table/IYSCallPermissionTable";
import { listProductIYSPermission } from "services/allServices";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGlobalStore } from "store/GlobalStore";
import { TailSpin } from "react-loader-spinner";

const IYSCallPermissions = () => {
    const { t } = useTranslation(["global", "services"]);
    const [data, setData] = useState();
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const language = localStorage.getItem("ln");

    const slug = useParams();

    const [permissionHistoryModal, setPermissionHistoryModal] = useState(false);

    const onListPermissionsCall = (page, limit) => {
        setLoading(true);
        let configure = {
            owned_product_id: slug.id,
            iys_type: "ARAMA"
        }
        listProductIYSPermission(page, limit, configure).then(res => {
            setData(res);
            setNumberOfPages(res?.number_of_pages);
            setLoading(false);
        })
    }

    useEffect(() => {

        const asyncFunction = async () => {
            let page = 1;
            if (location?.search) {
                page = location.search.split("=")[1];
            }
            await onListPermissionsCall(page, pageLimit);
        };

        asyncFunction();
    }, []);

    return (
        <>
            {
                loading ?
                    <div className="flex justify-center items-center mt-20 w-full">
                        <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                            type="Oval"
                            color="#ff7c30"
                            height={75}
                            width={75}
                            timeout={3000}
                        />
                    </div>
                    : <div>
                        <div className="flex justify-between items-center pt-5">
                            <div className="flex items-center space-x-4">
                                <p className="text-passive">{
                                    language === "tr" ? `Şu an toplamda ${data?.total_count} arama izni` : `Currently ${data?.total_count} call permissions in total`
                                }</p>
                                <p className="text-green-500">< i className="text-base ri-check-line"></i> <span>{language === 'tr' ? 'Onay' : 'Approval'} {data?.onay_count}</span></p>
                                <p className="text-red-500"><i class="text-base ri-close-line"></i><span>{language === 'tr' ? 'Ret' : 'Reject'} {data?.ret_count}</span></p>
                            </div>

                            {/* <div className="flex items-center space-x-4">
        <Button
            // onClick={() => setOpenModal(true)}
            className="flex items-center px-7"
            tertiary
        >
            <i className="ri-equalizer-line" />
            <span>{t("global:filter")}</span>
        </Button>
        <TextField
            id="search"
            name="search"
            type="search"
            placeholder={t("global:search")}
            className="lg:w-[300px]"
        />
    </div> */}
                        </div>

                        {data &&
                            <div className="pt-5">
                                <IYSCallPermissionTable data={data.data_list} setData={setData} onListPermissionsCall={onListPermissionsCall} numberOfPages={numberOfPages} />
                            </div>
                        }
                    </div>
            }
        </>

    )
}

export default IYSCallPermissions