import TextField from "components/FormElements/Input/TextField";
import React, { useEffect, useState } from "react";
import Button from "components/FormElements/Button";
import { generatePassword } from "helpers/helpersFunc";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  checkDoubleValidationInfoCode,
  getDoubleValidationInfo,
  logout,
  passwordCheck,
  sendDoubleValidationInfoCode,
  updatePasswordCheckCode,
  updatePasswordSendCode
} from "services/allServices";
import { useToasts } from "react-toast-notifications";
import RemoveLockModal from "components/Modals/RemoveLockModal";
import { useAuthStore } from "store/AuthStore";
import { useTranslation } from "react-i18next";

function SecuritySettings() {
  const { t, i18n } = useTranslation(["settings", "global", "nav", "reset"]);

  const { addToast } = useToasts();

  const [generatedPassword, setGeneratedPassword] = useState();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [openValidateModal, setOpenValidateModal] = useState(false);
  const [openDoubleValidateModal, setOpenDoubleValidateModal] = useState(false);

  const [setIsLoggin, setUserInformations, userInformations] = useAuthStore((state) => [state.setIsLoggin, state.setUserInformations, state.userInformations]);

  const [selectedCard, setSelectedCard] = useState("4");
  const onGeneratePassword = () => {
    let generatePass = generatePassword();
    setGeneratedPassword(generatePass);
    setNewPassword(generatePass);
    setNewPasswordAgain(generatePass);
  };

  const onLogout = () => {
    logout()
      .then((data) => {
        setIsLoggin(false);
        setUserInformations([]);
        let language = localStorage.getItem("ln");
        localStorage.clear();
        localStorage.setItem("ln", language);
      })
      .catch((err) => {
        setIsLoggin(false);
        setUserInformations([]);
        let language = localStorage.getItem("ln");
        localStorage.clear();
        localStorage.setItem("ln", language);
      });
  };

  const onSendCode = () => {
    if ((newPassword === oldPassword)) {
      return addToast(t("settings:cannotSamePassword"), {
        appearance: "error",
        autoDismiss: true
      });
    }
    if ((newPassword === newPasswordAgain) && (newPassword !== "" && newPasswordAgain !== "")) {
      let configure = {
        password_1: newPassword,
        password_2: newPasswordAgain
      }
      passwordCheck(configure).then(res => {
        if (!res?.status && res?.message === "password_lenght_not_enough") {
          addToast(t("settings:longPassword"), {
            appearance: "error",
            autoDismiss: true
          });
        } else if (!res?.status && !res?.message) {
          addToast(t("reset:passwordIncompatibleError"), {
            appearance: "error",
            autoDismiss: true
          });
        } else {
          updatePasswordSendCode(oldPassword).then(res => {
            if (res?.status === "password_does_not_match") {
              addToast(t("settings:oldPasswordIncorrect"), {
                appearance: "error",
                autoDismiss: true
              });
            } else {
              setOpenValidateModal(true);
            }

          });
        }

      });


    } else {
      addToast(t("settings:passwordNoMatch"), {
        appearance: "error",
        autoDismiss: true
      });
    }

  };
  const onUpdatePassword = (otp) => {
    updatePasswordCheckCode(otp, oldPassword, newPassword).then(res => {
      if (res.status == "invalid_password") {
        addToast(t("settings:passwordError"), {
          appearance: "error",
          autoDismiss: true
        });
      } else {
        addToast(t("settings:newPasswordChanged"), {
          appearance: "success",
          autoDismiss: true
        });
        setOpenValidateModal(false);
        setTimeout(() => {
          onLogout();
        }, 2000);
      }

    });
  };

  const onDoubleValidationSendCode = () => {
    let configure = {
      tfa_application: selectedCard === "1" ? true : false,
      tfa_mail: selectedCard === "2" ? true : false,
      tfa_sms: selectedCard === "3" ? true : false
    };
    sendDoubleValidationInfoCode(configure).then(res => {
      setOpenDoubleValidateModal(true);

    });
  };

  const onDoubleValidationCheckCode = (otp) => {
    let configure = {
      tfa_application: selectedCard === "1" ? true : false,
      tfa_mail: selectedCard === "2" ? true : false,
      tfa_sms: selectedCard === "3" ? true : false
    };
    checkDoubleValidationInfoCode(otp, configure).then(res => {
      if (res?.status === "not_match") {
        addToast(t("settings:notMatchCode"), {
          appearance: "error",
          autoDismiss: true
        });
      } else {
        setOpenDoubleValidateModal(false);
        addToast(t("global:itSaved"), {
          appearance: "success",
          autoDismiss: true
        });
      }

    });
  };


  const onGetDoubleValidationInfo = () => {
    getDoubleValidationInfo().then(res => {
      if (res.tfa_application) {
        setSelectedCard("1");
      }

      if (res.tfa_mail) {
        setSelectedCard("2");
      }

      if (res.tfa_sms) {
        setSelectedCard("3");
      }

    });
  };
  useEffect(() => {
    onGetDoubleValidationInfo();
  }, []);

  return (
    <div className="container">
      <h2 className="relative mt-5 mb-2 font-semibold">{t("settings:passwordChange")}</h2>
      <p>{t("settings:passwordChangeDesc")}</p>
      <div className="grid grid-cols-12 gap-4 mt-5 lg:gap-10">
        <div className="col-span-12 lg:col-span-3">
          <TextField
            id="oldPass"
            name="pass"
            value={oldPassword}
            onChange={setOldPassword}
            ref={null}
            type="password"
            label={t("settings:oldPassword")}
            placeholder="••••••••"
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 mt-5 lg:gap-10">
        <div className="col-span-12 lg:col-span-3">
          <TextField
            id="pass"
            name="pass"
            value={newPassword}
            onChange={setNewPassword}
            ref={null}
            type="password"
            label={t("settings:password")}
            placeholder="••••••••"
          />
        </div>
        <div className="col-span-12 lg:col-span-3">
          <TextField
            id="passAgain"
            name="passAgain"
            value={newPasswordAgain}
            onChange={setNewPasswordAgain}
            ref={null}
            type="password"
            label={t("settings:repassword")}
            placeholder="••••••••"
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 mt-5 lg:gap-10">
        <div className="col-span-12 lg:col-span-3">
          <Button
            onClick={() => onGeneratePassword()}
            className="w-full"
            secondary
          >
            {t("settings:create")}
          </Button>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <CopyToClipboard text={generatedPassword}>
            <Button className="w-full" secondary>
              {t("settings:copy")}
            </Button>
          </CopyToClipboard>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 mt-8 lg:gap-10">
        <div className="col-span-12 lg:col-span-3">
          <Button
            onClick={() => onSendCode()}
            primary
            className="flex justify-center items-center w-full"
            type="button"
          >
            {t("global:save")}
          </Button>
        </div>

      </div>
      <h2 className="mt-5 mb-2 font-semibold">{t("settings:doubleVerification")}</h2>
      <p>{t("settings:doubleVerificationDesc")}</p>
      <div className="grid grid-cols-8 gap-5">
        <div className="col-span-12 mt-5 lg:col-span-1">
          <div className={`w-full flex flex-col justify-center items-center bg-gray-300 p-6 cursor-pointer rounded-md shadow-md hover:shadow-lg ${selectedCard === "1" ? "border-2 border-primary-hover" : ""} `} >
            <i className="text-2xl ri-device-line"></i>
            <p>{t("settings:application")}</p>
          </div>
        </div>
        <div className="col-span-12 mt-5 lg:col-span-1">
          <div
            onClick={() => setSelectedCard("2")}
            className={`w-full flex flex-col justify-center items-center bg-white p-6 cursor-pointer rounded-md shadow-md hover:shadow-lg ${selectedCard === "2" ? "border-2 border-primary-hover" : ""} `}

          >
            <i className="text-2xl ri-mail-line"></i>
            <p>{t("global:email")}</p>
          </div>
        </div>
        <div className="col-span-12 mt-5 lg:col-span-1">
          <div
            onClick={() => userInformations?.customer_info?.customer_phone?.country_code?.id == "90" && setSelectedCard("3")}
            className={`w-full flex flex-col justify-center items-center ${userInformations?.customer_info?.customer_phone?.country_code?.id == "90" ? "bg-white" : "bg-gray-300"} bg-white p-6 cursor-pointer rounded-md shadow-md hover:shadow-lg ${selectedCard === "3" ? "border-2 border-primary-hover" : ""} `}
          >
            <i className="text-2xl ri-smartphone-line"></i>
            <p>SMS</p>
          </div>
        </div>
        <div className="col-span-12 mt-5 lg:col-span-1">
          <div
            onClick={() => setSelectedCard("4")}
            className={`w-full flex flex-col justify-center items-center bg-white p-6 cursor-pointer rounded-md shadow-md hover:shadow-lg ${selectedCard === "4" ? "border-2 border-primary-hover" : ""} `}

          >
            <i className="text-2xl ri-close-circle-line"></i>
            <p>{t("settings:notUsing")}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-4 mt-8 lg:gap-10">
        <div className="col-span-12 lg:col-span-3">
          <Button
            onClick={() => onDoubleValidationSendCode()}
            primary
            className="flex justify-center items-center w-full"
            type="button"
          >
            {t("global:save")}
          </Button>
        </div>

      </div>

      {openValidateModal &&
        <RemoveLockModal
          title={t("settings:passwordChange")}
          question={t("settings:passwordChangeDesc2")}
          buttonText={t("global:send")}
          setOpenModal={() => setOpenValidateModal(false)}
          onClick={(otp) => {
            onUpdatePassword(otp);
          }}
        />}


      {openDoubleValidateModal &&
        <RemoveLockModal
          title={t("settings:doubleVerificationChange")}
          question={t("settings:verificationMethodDesc")}
          buttonText={t("global:send")}
          setOpenModal={() => setOpenDoubleValidateModal(false)}
          onClick={(otp) => {
            onDoubleValidationCheckCode(otp);
          }}
        />}
    </div>
  );
}

export default SecuritySettings;
